.btn {
  width: 170px;
  height: 40px;
  line-height: 34px;
  margin-top: 12px;
  box-shadow: inset -1px 3px 8px 5px #1F87FF, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);

  a.button {
    color: #ffffff;
    text-transform: none;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    width: 170px;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgb(210, 4, 14) 100%);

    #arrow {
      display: none;
    }

    &.cta {
      > span {
        margin-left: 25px;
      }

      &:before {
        width: 0;
        background-color: #616bc6;
      }
    }
  }
}

.center-wrap {
  height: 376px;

  .title {
    color: #fff;
    font-size: 54px;
    line-height: 60px;
    font-family: 'Source Serif Pro';
    position: absolute;
    text-align: center;
    margin-top: 0;
    width: 100vw;
    font-weight: 700;
    text-shadow: 2px 2px #060505;
  }

  .left-content {
    max-width: 600px;
    padding-top: 155px;
    
    .subtitle {
      font-family: 'Source Serif Pro';
      font-size: 22px;
      line-height: normal;
    }

    .disclaimer_txt {
      width: 400px;
      height: 48px;
      color: #153f5b;
      font-family: 'Source Serif Pro';
      font-size: 12px;
      margin-top: 33px;
      line-height: normal;
    }
  }

  .right-content {
    padding-left: 40px;
    margin-top: 160px;
  }
}
