#dpFooter {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  background-color: rgba($color: #000000, $alpha: .5);

  .copyRight {
    padding-left: 50px;
    line-height: 71px;    
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    flex: 1;
  }

  .footerMenu {
    width: auto;
    text-align: right;
    padding-right: 50px;

    ul {
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      line-height: 71px;

      li {
        margin-left: 20px;
        float: left;

        a {
          text-decoration: none;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
        }
      }
    }
  }
}